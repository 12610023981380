export default Object.freeze({
  production: false,
  suraApi: "https://api-sura-lab-test.herokuapp.com",
  suraPort: 443,
  pagosApi: "https://apidigitallab.suraenlinea.com/apipagossel/v1/pago",
  pagosPort: 443,
  suraTimeout: 30000,
  flujoVentaApi: "https://apidigitallab.suraenlinea.com/apiventasdigitales/v1/ms-flujo-ventas",
  flujoVentaPort: 443,
  integradorApi: "https://apidigitallab.suraenlinea.com/apiventasdigitales/v1/ohs-ventas-digitales",
  integradorPort: 443,
  suraPath: "https://front-end-sel-test.herokuapp.com",
  twitterOembedApi: "https://api.twitter.com/1/statuses/oembed.json",
  prismicApi: "https://dev.suraenlinea.com/prismic/api/v2",
  googlereCAPTCHAKey: "6LfLzVcUAAAAAKe-UvVbGicBSh4P1VLW7WcWv-uV",
  googlereCAPTCHAKeyInvisible: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  headerSuscripcionNombre: "Api-Subscription-Key",
  headerSuscripcionValor: "75b4fa90875b44d3af1db36b20f41c3f",
  urlApiMaestros: "https://suraenlineaservicios.dllosura.com/maestros",
  tenants: [
    {
      tenant: "sura",
      hostname: "https://front-end-sel-test.herokuapp.com",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        globalSite: "UA-27527568-1",
        UA: "UA-27527568-1",
        optimizeId: "GTM-PSFM4S5",
      },
      facebookPixel: {
        code: "380531805706245",
        facebookEvents: ["PageView"],
      },
      url: "/",
      index: "/",
      experimentos: {
        contactoSkipAutos: "Glj4rxNbTM2f2Vp88OK6bA",
        contactoSkipMotos: "BGAlM98WTW2vKVwqsIh0Jw",
        landingMotos: "fxBE4qQVR5mk1Z9KiJSVkw",
        flujoMotos: "qvfl1zn6Qq2JSTkEFnxfzQ",
        flujoAutos: "NdOMnxc2RJ-CQS_Rl5VYqA",
      },
      codigoCanal: "7771",
      codigoAsesor: "4999",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: true,
        planEligeSura: true,
        perfilDigital: true,
        saludDigital: true,
      },
    },
    {
      tenant: "empleadossura",
      hostname: "",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        globalSite: "UA-27527568-1",
        UA: "UA-27527568-1",
        optimizeId: "GTM-PSFM4S5",
      },
      facebookPixel: {
        code: "380531805706245",
        facebookEvents: ["PageView"],
      },
      url: "/v2/empleadossura/soat/seguro-obligatorio",
      index: "/",
      experimentos: {
        contactoSkipAutos: "Glj4rxNbTM2f2Vp88OK6bA",
        contactoSkipMotos: "BGAlM98WTW2vKVwqsIh0Jw",
        landingMotos: "fxBE4qQVR5mk1Z9KiJSVkw",
        flujoMotos: "qvfl1zn6Qq2JSTkEFnxfzQ",
        flujoAutos: "NdOMnxc2RJ-CQS_Rl5VYqA",
      },
      codigoCanal: "287",
      codigoAsesor: "4999",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "exito",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-TS7M54",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "1002719383197429",
        facebookEvents: ["PageView", "AddToCart", "Lead", "AddPaymentInfo", "Purchase"],
      },
      url: "/seguros-exito",
      index: "indexExito",
      codigoCanal: "9462",
      codigoAsesor: "",
      restricciones: [
        "twitter",
        "bicicletas",
        "empresas",
        "soat.asesor",
        "menu.blog",
        "admin",
        "vehiculos.asesor",
        "vehiculos.conduce-mejor",
        "soat.compra-pago",
        "soat.historico-pagos",
        "vehiculos.porque-debes-contratar-un-seguro",
        "vehiculos.plan-pesados-utilitarios",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "vehiculos.plan-global",
        "seguro-digital",
        "user-voice",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "exito",
      hostname: "frontend-sura-lab.herokuapp.com",
      googleTagManager: {
        GTM: "GTM-TS7M54",
        UA: "UA-27527568-59",
        optimizeId: "",
      },
      facebookPixel: {
        code: "1002719383197429",
        facebookEvents: ["PageView", "AddToCart", "Lead", "AddPaymentInfo", "Purchase"],
      },
      url: "/seguros-exito",
      index: "indexExito",
      codigoCanal: "9462",
      codigoAsesor: "",
      restricciones: [
        "twitter",
        "bicicletas",
        "empresas",
        "soat.asesor",
        "menu.blog",
        "admin",
        "vehiculos.asesor",
        "vehiculos.conduce-mejor",
        "soat.compra-pago",
        "soat.historico-pagos",
        "vehiculos.porque-debes-contratar-un-seguro",
        "vehiculos.plan-pesados-utilitarios",
        "soat.promociones",
        "vehiculos.promociones",
        "vehiculos.plan-global",
        "seguro-digital",
        "user-voice",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "clickam",
      hostname: "https://www.segurosexito.com/",
      googleTagManager: {
        GTM: "",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "1002719383197429",
        facebookEvents: ["PageView", "AddToCart", "Lead", "AddPaymentInfo", "Purchase"],
      },
      userVoice: "qvTt3wLRJ3FqivEdmXlRHw",
      url: "/v2/clickam/soat/seguro-obligatorio",
      codigoCanal: "124",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        preguntasFrecuentes: true,
      },
    },
    {
      tenant: "exito",
      hostname: "compra.segurosexito.com",
      googleTagManager: {
        GTM: "GTM-TS7M54",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "1002719383197429",
        facebookEvents: ["PageView", "AddToCart", "Lead", "AddPaymentInfo", "Purchase"],
      },
      url: "/seguros-exito",
      index: "indexExito",
      codigoCanal: "9462",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "exitocom",
      index: "exito-com",
      hostname: "https://www.exito.com/",
      url: "",
      codigoCanal: "295",
      codigoAsesor: "",
      googleTagManager: {
        GTM: "GTM-5X9G7P",
        UA: "UA-27527568-59",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "cdiscount",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-5X9G7P",
        UA: "UA-27527568-59",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/cdiscount",
      index: "indexCdiscount",
      codigoCanal: "9461",
      codigoAsesor: "",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "soat.asesor",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "bancolombia",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-PHSGXJ",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/v2/bancolombia/soat/seguro-obligatorio",
      index: "indexBancolombia",
      codigoCanal: "14619",
      codigoAsesor: "4999",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "soat.asesor",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "bancolombia",
      hostname: "front-end-sel-test.herokuapp.com",
      googleTagManager: {
        GTM: "GTM-PHSGXJ",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/v2/bancolombia/soat/seguro-obligatorio",
      index: "indexBancolombia",
      codigoCanal: "12319",
      codigoAsesor: "4999",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "soat.asesor",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "sufi",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-55CQGZR",
        UA: "UA-27527568-71",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/v2/sufi/soat/seguro-obligatorio",
      index: "indexSufi",
      codigoCanal: "12319",
      codigoAsesor: "9993",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "soat.asesor",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "flypass",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-PLSRCT",
        UA: "UA-27527568-63",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/flypass",
      index: "indexFlypass",
      codigoCanal: "11093",
      codigoAsesor: "29095",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "soat.asesor",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "terpel",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-5RB2B8",
        UA: "UA-27527568-66",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/terpel",
      index: "indexTerpel",
      codigoCanal: "11300",
      codigoAsesor: "",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "carulla",
      hostname: "https://www.carulla.com/",
      url: "",
      index: "/carulla",
      codigoCanal: "9656",
      codigoAsesor: "",
      googleTagManager: {
        GTM: "GTM-KH4PJ2",
        UA: "UA-27527568-65",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "busqo",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/busqo",
      index: "indexBusqo",
      codigoCanal: "",
      codigoAsesor: "13765",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "alkosto",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/alkosto",
      index: "indexAlkosto",
      codigoCanal: "13824",
      codigoAsesor: "43880",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "porque-asegurarte",
        "user-voice",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "ktronix",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/ktronix",
      index: "indexKtronix",
      codigoCanal: "359",
      codigoAsesor: "",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "porque-asegurarte",
        "user-voice",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
      },
    },
    {
      tenant: "presentecom",
      hostname: "https://www.presente.com.co/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/presentecom/soat/seguro-obligatorio",
      codigoCanal: "128",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "alkomprar",
      hostname: "https://www.alkomprar.com/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/alkomprar/soat/seguro-obligatorio",
      codigoCanal: "87",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "foton",
      hostname: "https://foton.com.co/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/foton/soat/seguro-obligatorio",
      codigoCanal: "353",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "tuya",
      hostname: "https://www.tuya.com.co/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/tuya/soat/seguro-obligatorio",
      codigoCanal: "133",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "euro",
      hostname: "http://www.eurosupermercados.com/",
      googleTagManager: {
        GTM: "GTM-MMQ5T9T",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/euro/soat/seguro-obligatorio",
      codigoCanal: "82",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "colsubsidio",
      hostname: "https://www.colsubsidio.com/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        globalSite: "UA-27527568-1",
        UA: "UA-27527568-1",
        optimizeId: "GTM-PSFM4S5",
      },
      facebookPixel: {
        code: "380531805706245",
        facebookEvents: ["PageView"],
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/",
      codigoCanal: "7771",
      codigoAsesor: "4999",
      canActivateRoute: {
        preguntasFrecuentes: true,
      },
    },
  ],
});
