export default Object.freeze({
  production: false,
  GTM: "GTM-5R4TXN",
  UA: "UA-27527568-1",
  OPTIMIZE_ID: "GTM-PSFM4S5",
  suraApi: "https://apilab.suraenlinea.com",
  suraPort: 443,
  pagosApi: "https://suraenlineaservicios.labsura.com/ms-pagos/pago",
  pagosPort: 443,
  flujoVentaApi: "https://suraenlineaservicios.labsura.com/ms-flujo-ventas-digitales",
  flujoVentaPort: 443,
  integradorApi: "https://suraenlineaservicios.labsura.com/ohs-ventas-digitales",
  integradorPort: 443,
  suraTimeout: 30000,
  webchatTibotScript:
    'https://parly-webchat-suraco-mastertibot.1jp7r741wpkb.us-east.codeengine.appdomain.cloud/arl/js/chat-control.js?bot=suraenlinea',
  webchatTibotLogo:
    'https://parly-webchat-suraco-mastertibot.1jp7r741wpkb.us-east.codeengine.appdomain.cloud/arl/images/logo.png',
  suraPath: "https://lab.suraenlinea.com",
  twitterOembedApi: "https://api.twitter.com/1/statuses/oembed.json",
  prismicApi: "https://lab.suraenlinea.com/prismic/api/v2",
  googlereCAPTCHAKey: "6LfLzVcUAAAAAKe-UvVbGicBSh4P1VLW7WcWv-uV",
  googlereCAPTCHAKeyInvisible: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  googlereCAPTCHAV3Key: "6Lfrc6MZAAAAAEJJ2ouRiJ5zxliFnmWo0OGVFNzw",
  endpointSalesforce: "https://test.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8",
  oidSalesforce: "00Dm00000003doV",
  urlApiMaestros: "https://suraenlineaservicios.labsura.com/maestros",
  headerSuscripcionNombre: "Api-Subscription-Key",
  headerSuscripcionValor: "75b4fa90875b44d3af1db36b20f41c3f",
  tenants: [
    {
      tenant: "sura",
      hostname: "https://lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        globalSite: "UA-27527568-1",
        UA: "UA-27527568-1",
        optimizeId: "GTM-PSFM4S5",
      },
      facebookPixel: {
        code: "380531805706245",
        facebookEvents: ["PageView"],
      },
      url: "/",
      codigoCanal: "7771",
      codigoAsesor: "4999",
      userVoice: "fstYbP5lDoofPPZa8JFig",
      canActivateRoute: {
        autos: true,
        motos: true,
        bicicletas: true,
        creditoProtegido: true,
        proteccionLegal: true,
        seguroDeViaje: true,
        soat: true,
        proteccionDigitalPersonas: true,
        planEligeSura: true,
        perfilDigital: true,
        preguntasFrecuentes: true,
      },
    },
    {
      tenant: "empleadossura",
      hostname: "",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        globalSite: "UA-27527568-1",
        UA: "UA-27527568-1",
        optimizeId: "GTM-PSFM4S5",
      },
      facebookPixel: {
        code: "380531805706245",
        facebookEvents: ["PageView"],
      },
      url: "/v2/empleadossura/soat/seguro-obligatorio",
      codigoCanal: "287",
      codigoAsesor: "4999",
      userVoice: "fstYbP5lDoofPPZa8JFig",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "exito",
      hostname: "https://www.segurosexito.com/",
      googleTagManager: {
        GTM: "GTM-TBVGMRW",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "1002719383197429",
        facebookEvents: ["PageView", "AddToCart", "Lead", "AddPaymentInfo", "Purchase"],
      },
      url: "/v2/exito/soat/seguro-obligatorio",
      codigoCanal: "295",
      codigoAsesor: "",
      canActivateRoute: {
        autos: true,
        motos: true,
        bicicletas: false,
        creditoProtegido: true,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
        preguntasFrecuentes: true,
      },
    },
    {
      tenant: "clickam",
      hostname: "https://www.segurosexito.com/",
      googleTagManager: {
        GTM: "",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "1002719383197429",
        facebookEvents: ["PageView", "AddToCart", "Lead", "AddPaymentInfo", "Purchase"],
      },
      userVoice: "qvTt3wLRJ3FqivEdmXlRHw",
      url: "/v2/clickam/soat/seguro-obligatorio",
      codigoCanal: "124",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        preguntasFrecuentes: true,
      },
    },
    {
      tenant: "exitocom",
      hostname: "https://www.exito.com/",
      googleTagManager: {
        GTM: "GTM-5X9G7P",
        UA: "UA-27527568-59",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "",
      index: "indexExitocom",
      codigoCanal: "295",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "cdiscount",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-5X9G7P",
        UA: "UA-27527568-59",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/cdiscount",
      index: "indexCdiscount",
      codigoCanal: "9461",
      codigoAsesor: "",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "soat.asesor",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "bancolombia",
      hostname: "https://www.grupobancolombia.com",
      googleTagManager: {
        GTM: "GTM-PHSGXJ",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/v2/bancolombia/soat/seguro-obligatorio",
      index: "indexBancolombia",
      codigoCanal: "346",
      codigoAsesor: "4999",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
        preguntasFrecuentes: true,
      },
    },
    {
      tenant: "sufi",
      hostname: "https://sufi.grupobancolombia.com/wps/portal/sufi",
      googleTagManager: {
        GTM: "GTM-55CQGZR",
        UA: "UA-27527568-71",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/v2/sufi/soat/seguro-obligatorio",
      index: "indexSufi",
      codigoCanal: "354",
      codigoAsesor: "9993",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "soat.asesor",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "flypass",
      hostname: "https://flypass.com.co/",
      googleTagManager: {
        GTM: "GTM-PLSRCT",
        UA: "UA-27527568-63",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/v2/flypass/soat/seguro-obligatorio",
      index: "indexFlypass",
      codigoCanal: "355",
      codigoAsesor: "29095",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "soat.asesor",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
        preguntasFrecuentes: true,
      },
    },
    {
      tenant: "terpel",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-5RB2B8",
        UA: "UA-27527568-66",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/terpel",
      index: "indexTerpel",
      codigoCanal: "11300",
      codigoAsesor: "",
      restricciones: [
        "twitter",
        "vehiculos",
        "empresas",
        "bicicletas",
        "credito-protegido",
        "menu.blog",
        "admin",
        "chat",
        "soat.promociones",
        "vehiculos.promociones",
        "motos.promociones",
        "soat.tarifas",
        "soat.compra-pago",
        "soat.historico-pagos",
        "seguro-digital",
        "cyber",
      ],
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "carulla",
      hostname: "https://www.carulla.com/",
      googleTagManager: {
        GTM: "GTM-KH4PJ2",
        UA: "UA-27527568-65",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "",
      codigoCanal: "345",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "busqo",
      hostname: "lab.suraenlinea.com",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/busqo",
      codigoCanal: "",
      codigoAsesor: "13765",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "alkosto",
      hostname: "https://www.alkosto.com/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "/v2/alkosto/soat/seguro-obligatorio",
      codigoCanal: "347",
      codigoAsesor: "43880",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "ktronix",
      hostname: "https://www.ktronix.com/",
      googleTagManager: {
        GTM: "",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      url: "",
      codigoCanal: "359",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
      },
    },
    {
      tenant: "euro",
      hostname: "http://www.eurosupermercados.com/",
      googleTagManager: {
        GTM: "GTM-MMQ5T9T",
        UA: "",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/euro/soat/seguro-obligatorio",
      codigoCanal: "82",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "bancodeoccidente",
      hostname: "https://www.bancodeoccidente.com.co",
      googleTagManager: {
        GTM: "GTM-5386RGL",
        UA: "UA-27527568-1",
        globalSite: "UA-3571024-60",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/bancodeoccidente/soat/seguro-obligatorio",
      codigoCanal: "301",
      codigoAsesor: "",
      canActivateRoute: {
        autos: false,
        motos: false,
        bicicletas: false,
        creditoProtegido: false,
        proteccionLegal: false,
        seguroDeViaje: false,
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
        preguntasFrecuentes: true,
      },
    },
    {
      tenant: "exitoatendido",
      hostname: "https://atendido.exito.com/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "",
      codigoCanal: "295",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "presentecom",
      hostname: "https://www.presente.com.co/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/presentecom/soat/seguro-obligatorio",
      codigoCanal: "128",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "alkomprar",
      hostname: "https://www.alkomprar.com/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/alkomprar/soat/seguro-obligatorio",
      codigoCanal: "87",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "foton",
      hostname: "https://foton.com.co/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/foton/soat/seguro-obligatorio",
      codigoCanal: "353",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "tuya",
      hostname: "https://www.tuya.com.co/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        UA: "UA-27527568-1",
        optimizeId: "",
      },
      facebookPixel: {
        code: "",
        facebookEvents: "",
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/v2/tuya/soat/seguro-obligatorio",
      codigoCanal: "133",
      codigoAsesor: "",
      canActivateRoute: {
        soat: true,
        proteccionDigitalPersonas: false,
        planEligeSura: false,
        perfilDigital: false,
        saludDigital: false,
      },
    },
    {
      tenant: "colsubsidio",
      hostname: "https://www.colsubsidio.com/",
      googleTagManager: {
        GTM: "GTM-5R4TXN",
        globalSite: "UA-27527568-1",
        UA: "UA-27527568-1",
        optimizeId: "GTM-PSFM4S5",
      },
      facebookPixel: {
        code: "380531805706245",
        facebookEvents: ["PageView"],
      },
      userVoice: "fstYbP5lDoofPPZa8JFig",
      url: "/",
      codigoCanal: "7771",
      codigoAsesor: "4999",
      canActivateRoute: {
        preguntasFrecuentes: true,
      },
    },
  ],
});
