// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
         production: false,
         redirigir: false,
         suraApi: "https://apilab.suraenlinea.com",
         suraPort: 443,
         pagosApi: "https://apidigitallab.suraenlinea.com/apipagossel/v1/pago",
         pagosPort: 443,
         flujoVentaApi: "https://apidigitallab.suraenlinea.com/apiventasdigitales/v1/ms-flujo-ventas",
         flujoVentaPort: 443,
         integradorApi: "https://apidigitallab.suraenlinea.com/apiventasdigitales/v1/ohs-ventas-digitales",
         integradorPort: 443,
         suraTimeout: 30000,
         suraPath: "http://localhost:4200",
         prismicApi: "https://dev.suraenlinea.com/prismic/api/v2",
         googlereCAPTCHAKey: "6LfLzVcUAAAAAKe-UvVbGicBSh4P1VLW7WcWv-uV",
         googlereCAPTCHAKeyInvisible: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
         headerSuscripcionNombre: "Api-Subscription-Key",
         headerSuscripcionValor: "75b4fa90875b44d3af1db36b20f41c3f",
         tenants: [
           {
             tenant: "sura",
             hostname: "http://localhost:4200",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               globalSite: "UA-27527568-1",
               UA: "UA-27527568-1",
               optimizeId: "GTM-PSFM4S5",
             },
             facebookPixel: {
               code: "380531805706245",
               facebookEvents: ["PageView"],
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/",
             codigoCanal: "7771",
             codigoAsesor: "4999",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: true,
               planEligeSura: true,
               perfilDigital: true,
               preguntasFrecuentes: true,
             },
           },
           {
             tenant: "empleadossura",
             hostname: "",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               globalSite: "UA-27527568-1",
               UA: "UA-27527568-1",
               optimizeId: "GTM-PSFM4S5",
             },
             facebookPixel: {
               code: "380531805706245",
               facebookEvents: ["PageView"],
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/empleadossura/soat/seguro-obligatorio",
             codigoCanal: "287",
             codigoAsesor: "4999",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "exito",
             hostname: "https://www.segurosexito.com/",
             googleTagManager: {
               GTM: "GTM-TS7M54",
               UA: "UA-27527568-59",
               optimizeId: "",
             },
             facebookPixel: {
               code: "1002719383197429",
               facebookEvents: ["PageView", "AddToCart", "Lead", "AddPaymentInfo", "Purchase"],
             },
             userVoice: "qvTt3wLRJ3FqivEdmXlRHw",
             url: "/v2/exito/soat/seguro-obligatorio",
             codigoCanal: "9462",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
               preguntasFrecuentes: true,
             },
           },
           {
             tenant: "clickam",
             hostname: "https://www.segurosexito.com/",
             googleTagManager: {
               GTM: "GTM-TS7M54",
               UA: "UA-27527568-59",
               optimizeId: "",
             },
             facebookPixel: {
               code: "1002719383197429",
               facebookEvents: ["PageView", "AddToCart", "Lead", "AddPaymentInfo", "Purchase"],
             },
             userVoice: "qvTt3wLRJ3FqivEdmXlRHw",
             url: "/v2/clickam/soat/seguro-obligatorio",
             codigoCanal: "124",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
               preguntasFrecuentes: true,
             },
           },
           {
             tenant: "exitocom",
             hostname: "https://www.exito.com/",
             googleTagManager: {
               GTM: "GTM-5X9G7P",
               UA: "UA-27527568-59",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "qvTt3wLRJ3FqivEdmXlRHw",
             url: "/v2/exitocom/soat/seguro-obligatorio",
             index: "indexExitocom",
             codigoCanal: "295",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "cdiscount",
             hostname: "local.suraenlinea.com:9000",
             googleTagManager: {
               GTM: "GTM-5X9G7P",
               UA: "UA-27527568-59",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "qvTt3wLRJ3FqivEdmXlRHw",
             url: "/cdiscount",
             index: "indexCdiscount",
             codigoCanal: "9461",
             codigoAsesor: "",
             restricciones: [
               "twitter",
               "vehiculos",
               "empresas",
               "bicicletas",
               "credito-protegido",
               "soat.asesor",
               "menu.blog",
               "admin",
               "chat",
               "soat.promociones",
               "vehiculos.promociones",
               "motos.promociones",
               "soat.tarifas",
               "soat.compra-pago",
               "soat.historico-pagos",
               "seguro-digital",
               "cyber",
             ],
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "bancolombia",
             hostname: "local.suraenlinea.com:9000",
             googleTagManager: {
               GTM: "GTM-PHSGXJ",
               UA: "",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/bancolombia/soat/seguro-obligatorio",
             codigoCanal: "14619",
             codigoAsesor: "4999",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
               preguntasFrecuentes: true,
             },
           },
           {
             tenant: "sufi",
             hostname: "https://sufi.grupobancolombia.com/wps/portal/sufi",
             googleTagManager: {
               GTM: "GTM-55CQGZR",
               UA: "UA-27527568-71",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/sufi/soat/seguro-obligatorio",
             codigoCanal: "12319",
             codigoAsesor: "9993",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "flypass",
             hostname: "https://flypass.com.co/",
             googleTagManager: {
               GTM: "GTM-PLSRCT",
               UA: "UA-27527568-63",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/flypass/soat/seguro-obligatorio",
             codigoCanal: "11093",
             codigoAsesor: "29095",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
               preguntasFrecuentes: true,
             },
           },
           {
             tenant: "terpel",
             hostname: "local.suraenlinea.com:9000",
             googleTagManager: {
               GTM: "GTM-5RB2B8",
               UA: "UA-27527568-66",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/terpel",
             index: "indexTerpel",
             codigoCanal: "11300",
             codigoAsesor: "",
             restricciones: [
               "twitter",
               "vehiculos",
               "empresas",
               "bicicletas",
               "credito-protegido",
               "menu.blog",
               "admin",
               "chat",
               "soat.promociones",
               "vehiculos.promociones",
               "motos.promociones",
               "soat.tarifas",
               "soat.compra-pago",
               "soat.historico-pagos",
               "seguro-digital",
               "cyber",
             ],
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "carulla",
             hostname: "https://www.carulla.com/",
             googleTagManager: {
               GTM: "GTM-KH4PJ2",
               UA: "UA-27527568-65",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "qvTt3wLRJ3FqivEdmXlRHw",
             url: "/v2/carulla/soat/seguro-obligatorio",
             codigoCanal: "9656",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "busqo",
             hostname: "local.suraenlinea.com:9000",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/busqo",
             index: "indexBusqo",
             codigoCanal: "",
             codigoAsesor: "13765",
             restricciones: [
               "twitter",
               "vehiculos",
               "empresas",
               "bicicletas",
               "credito-protegido",
               "menu.blog",
               "admin",
               "chat",
               "soat.promociones",
               "vehiculos.promociones",
               "motos.promociones",
               "soat.tarifas",
               "soat.compra-pago",
               "soat.historico-pagos",
               "seguro-digital",
               "cyber",
             ],
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "alkosto",
             hostname: "https://www.alkosto.com/",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/alkosto/soat/seguro-obligatorio",
             codigoCanal: "13824",
             codigoAsesor: "43880",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "euro",
             hostname: "http://www.eurosupermercados.com/",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/euro/soat/seguro-obligatorio",
             codigoCanal: "82",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "bancodeoccidente",
             hostname: "https://www.bancodeoccidente.com.co",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/",
             codigoCanal: "",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
               preguntasFrecuentes: true,
             },
           },
           {
             tenant: "exitoatendido",
             hostname: "https://atendido.exito.com/",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "",
             codigoCanal: "295",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "presentecom",
             hostname: "https://www.presente.com.co/",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/presentecom/soat/seguro-obligatorio",
             codigoCanal: "",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "alkomprar",
             hostname: "https://www.alkomprar.com/",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/alkomprar/soat/seguro-obligatorio",
             codigoCanal: "",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "foton",
             hostname: "https://foton.com.co/",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/foton/soat/seguro-obligatorio",
             codigoCanal: "353",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "tuya",
             hostname: "https://www.tuya.com.co/",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               UA: "UA-27527568-1",
               optimizeId: "",
             },
             facebookPixel: {
               code: "",
               facebookEvents: "",
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/v2/tuya/soat/seguro-obligatorio",
             codigoCanal: "",
             codigoAsesor: "",
             canActivateRoute: {
               soat: true,
               proteccionDigitalPersonas: false,
               planEligeSura: false,
               perfilDigital: false,
             },
           },
           {
             tenant: "colsubsidio",
             hostname: "https://www.colsubsidio.com/",
             googleTagManager: {
               GTM: "GTM-5R4TXN",
               globalSite: "UA-27527568-1",
               UA: "UA-27527568-1",
               optimizeId: "GTM-PSFM4S5",
             },
             facebookPixel: {
               code: "380531805706245",
               facebookEvents: ["PageView"],
             },
             userVoice: "fstYbP5lDoofPPZa8JFig",
             url: "/",
             codigoCanal: "7771",
             codigoAsesor: "4999",
             canActivateRoute: {
               preguntasFrecuentes: true,
             },
           },
         ],
       };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
